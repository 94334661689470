@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: local("Calibri Regular"), local("Calibri-Regular"),
    url(./roboto-regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url(./roboto-bold.ttf) format("truetype");
}

@font-face {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: local("Calibri Regular"), local("Calibri-Regular"),
    url(./calibri.ttf) format("truetype");
}

@font-face {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: local("Calibri Bold"), local("Calibri-Bold"),
    url(./calibri-bold.ttf) format("truetype");
}

body {
  overflow-y: scroll;
}
