.mentions {
  width: 100%;
  margin-bottom: 24px;
}

.mentions--multiLine .mentions__control {
  min-height: 30px;
}
.mentions--multiLine .mentions__highlighter {
  padding: 4px 11px;
  border: 1px solid transparent;
}
.mentions--multiLine .mentions__input {
  border: 1px solid #000;
  padding: 4px 11px;
  border-radius: 4px;
  font-size: inherit;
  transition: all 300ms;
}
.mentions--multiLine .mentions__input:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}
.mentions--multiLine .mentions__input::placeholder {
  color: #c3c3c3;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  background-color: #eff4fc;
  color: #000;
  pointer-events: none;
}
